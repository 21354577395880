import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { Link } from 'gatsby'

const Caret = loadable(() => import('/src/components/Basic/Caret'))
const BreadcrumbContainer = loadable(() =>
    import('/src/components/Structure/Breadcrumb/BreadcrumbContainer')
)

const BackBreadcrumb = ({ size, title, uri, resetFunction }) => {
    return (
        <BreadcrumbContainer size={size}>
            <Caret variant={'back'} />
            <Link
                to={uri}
                onClick={resetFunction ? () => resetFunction() : null}
            >
                Back to {title}
            </Link>
        </BreadcrumbContainer>
    )
}

BackBreadcrumb.propTypes = {
    size: PropTypes.oneOf(['standard', 'narrow', 'narrower']),
    title: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    resetFunction: PropTypes.func
}

BackBreadcrumb.defaultProps = {
    size: 'narrower',
    title: 'Page Name',
    uri: '/page-url/'
}

export default BackBreadcrumb
